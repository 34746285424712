import * as React from 'react'
import styled from 'styled-components'
import {Flex} from '../../components/Box'
import {Text} from '../../components/Text'
import {useMediaQuery} from '@mui/material'
import AboutUsImage from '../../images/AboutUsImage.png'
import PartnersSlider from '../../components/partnersSlider'

const MainWrapper = styled(Flex)`
	width: 100%;
	height: 100%;
	max-width: 1215px;
	min-height: 900px;
	margin: 0 auto;
	padding: 32px;
	@media (max-width: 900px) {
		overflow: hidden;
	}
`

const TextWrapper = styled(Flex)`
	gap: 28px;
	z-index: 666;
	width: 100%;
	max-width: 500px;
	position: relative;
	margin: 140px 0 0 0;
	height: max-content;
	flex-direction: column;
	@media (max-width: 900px) {
		margin: 64px 0 254px 0;
	}
`

const ImageWrapper = styled(Flex)`
	position: absolute;
	z-index: -20;
	top: 90%;
	left: 50%;
	transform: translate(-50%, -50%);
	@media (max-width: 900px) {
		top: 70%;
	}
`

const StyledRow = styled(Flex)`
	gap: 64px;
	@media (max-width: 900px) {
		gap: 0;
	}
`

const StyledText = styled(Text)<{bold?: boolean}>`
	font-size: 16px;
	font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
	color: ${({bold}) => (bold ? '#f3f3f3' : '#d9d9d9')};
`

const StyledTextHighlighted = styled(Text)`
	font-size: 36px;
	line-height: 115%;
	font-wieght: bold;
	background: linear-gradient(139.46deg, #08f4e3 0.3%, #9b77f5 86.83%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`

const StyledAnchor = styled.a`
	text-decoration: none;
	color: #f3f3f3;
	:hover {
		opacity: 0.8;
	}
`

const IndexPage: React.FC = () => {
	const mobileDisplay = useMediaQuery('(max-width:900px)')
	return (
		<>
			<MainWrapper>
				<Flex
					width='100%'
					justifyContent={mobileDisplay ? 'flex-start' : 'space-between'}
					flexDirection={mobileDisplay ? 'column' : 'row'}
				>
					<TextWrapper>
						<Text fontSize='48px' fontWeight='bold'>
							About us
						</Text>
						<Text fontSize='14px'>
							Software house which specialises in web3, AR/VR, mobile
							applications, frontend and backend development for gaming and
							metaverse industries.
						</Text>
					</TextWrapper>
					<TextWrapper>
						<StyledTextHighlighted>
							Augumented life studio
						</StyledTextHighlighted>
						<StyledRow>
							<Flex flexDirection='column' maxWidth='170px'>
								<StyledText bold>Principal office</StyledText>
								<StyledText>
									State of Delaware, 1013 Centre Road Suite 403-A
								</StyledText>
								<StyledText paddingTop='32px'>
									Wilmington, county of Newcastle
								</StyledText>
								<Flex style={{gap: '5px'}}>
									<StyledText bold>Zip code</StyledText>
									<StyledText>19805</StyledText>
								</Flex>
							</Flex>
							<Flex flexDirection='column' maxWidth='170px'>
								<Flex style={{gap: '5px'}}>
									<StyledText bold>EIN</StyledText>
									<StyledText>61-2040294</StyledText>
								</Flex>
								<Flex style={{gap: '5px'}}>
									<StyledText bold>File Number</StyledText>
									<StyledText>6882009</StyledText>
								</Flex>
								<Flex flexDirection='column' paddingTop='32px'>
									<StyledText bold>Phone</StyledText>
									<StyledText>+1 (520) 600 2734</StyledText>
								</Flex>
								<Flex flexDirection='column' paddingTop='32px'>
									<StyledText bold>E-mail</StyledText>
									<StyledAnchor href='mailto:info@augmentedlife.studio'>
										info@augmentedlife.studio
									</StyledAnchor>
								</Flex>
							</Flex>
						</StyledRow>
						<ImageWrapper>
							<img src={AboutUsImage} />
						</ImageWrapper>
					</TextWrapper>
				</Flex>
			</MainWrapper>
			<PartnersSlider />
		</>
	)
}

export default IndexPage
